<template>
	<div class="content">
		<div class="header-section icon-list detailed">
			<div class="header-with-tabs row-space-tbf">
				<div class="space-left"></div>
                <div class="content">
                    <div class="tabs">
                        <div class="title-tab active">{{ $t('report_users.title') }}</div>
                    </div>
                    <div class="actions">
                    	<div class="filter-dropdown-header dropdown">
                    		<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    			<span class="text" v-if="!selectedStatus">{{ $t('filters.by_status') }}</span>
                    			<span class="text" v-else>{{ $t(`user_archivate.status.${selectedStatus}`) }}</span>

                    			<div class="icon-filter" v-if="!selectedStatus"><icon-arrow /></div>
                    			<div class="icon-clear" @click.stop="clearFilter('selectedStatus')" v-else><icon-close /></div>
                    		</button>
                    		<div class="dropdown-menu" aria-labelledby="dropdownFilter">
                    			<template v-for="status in usersStatusList">
                    				<div class="dropdown-item" @click.stop="selectSingleOptionFilter('selectedStatus', status)" v-bind:class="{active: selectedStatus.includes(status)}" :key="'filter-status-' + status">
                    					<div class="checkbox">
                    						<div class="checkmark"></div><span class="text">{{ $t(`user_archivate.status.${status}`) }}</span>
                    					</div>
                    				</div>
                    			</template>
                    		</div>
                    	</div>

                    	<div class="filter-btns">
							<date-range-picker
								ref="picker"
								class="calendar-filter report-calendar"
								:class="[{mobile: $resize && $mq.below(500)}]"
								opens="left"
								:locale-data="calendarFilterSettings"
								:singleDatePicker="$mq.below(650) ? 'range' : false"
								:showWeekNumbers="false"
								:showDropdowns="false"
								:ranges="false"
								:autoApply="true"
								:linkedCalendars="true"
								:maxDate="maxDateCalendar"
								v-model="dateRange"
								>
								<template v-slot:input="picker">
									<div class="input-picker">
										<div class="icon"><icon-date/></div>
										<div class="date" v-if="picker.startDate == picker.endDate">{{ picker.startDate | moment('DD MMM YY') }}</div>
										<div class="date" v-else>{{ picker.startDate | moment('DD MMM YY') }} - {{ picker.endDate | moment('DD MMM YY') }}</div>
										<icon-arrow class="icon-arrow right"/>
									</div>
								</template>
							</date-range-picker>
						</div>

                    	<button class="btn-tbf blue center" v-bind:class="{'only-icon': $resize && $mq.below(850)}" @click="donwloadXLS">
                            <span class="text" v-if="$resize && $mq.above(850)">{{ $t('report_users.download_xls') }}</span>
                            <icon-download class="icon" v-else />
                        </button>
                    </div>
                </div>
                <div class="space-right"></div>
            </div>
		</div>

		<div class="header-filter" v-if="loaded">
			<div class="row-space-tbf search-row with-border">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('report_users.search_by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
		<loader-header-list-without-btn v-else/>

		<template v-if="loadedList">
			<div class="data-list list-goals" v-if="users.length">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter sortable column-name" @click="sortList('first_name')" v-bind:class="[sortBy == 'first_name' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('general.name') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-total">
							<div class="text">{{ $t('report_users.total_responsibilities') }}</div>
						</div>
						<div v-if="$resize && $mq.above(700)" class="column-filter column-accomplished">
							<div class="text">{{ $t('report_users.accomplished_responsibilities') }}</div>
						</div>
						<div v-if="$resize && $mq.above(1040)" class="column-filter column-unaccomplished">
							<div class="text">{{ $t('report_users.unaccomplished_responsibilities') }}</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="user in users">
					<div class="row-space-tbf row-list-item">
						<div class="space-left"><icon-people v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
							<div class="column-name-desc column-avatar column-name">
								<img :src="user.avatar" v-if="user.avatar">
								<div class="user-circle" v-else>
									<icon-user />
								</div>
								<div class="text">
									<div class="name">{{ user.name }}</div>
									<div class="description" v-if="!user.dummy_account">{{ user.email }}</div>
								</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-simple-text column-total">
								<div class="text link" @click="showModal('report_user_responsibilities', {slug: user.slug, name: user.name, start_date: dateRange.startDate, end_date: dateRange.endDate})">{{ user.responsibilities.total }}</div>
							</div>
							<div v-if="$resize && $mq.above(700)" class="column-simple-text column-accomplished">
								<div class="text">{{ user.responsibilities.accomplished }}</div>
							</div>
							<div v-if="$resize && $mq.above(1040)" class="column-simple-text column-unaccomplished">
								<div class="text">{{ user.responsibilities.unaccomplished }}</div>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<div class="title">{{ $t('empty.report-title')}}</div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list v-else/>
		
		<infinite-loading :identifier="infiniteId" @infinite="infiniteUsers" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</template>

<script>
    import IconArrow from '../../Icons/Arrow'
    import IconClose from '../../Icons/Close'
    import IconUser from '../../Icons/User'
    import IconPeople from '../../Icons/People'
	import IconSearch from '../../Icons/Search'
	import IconDate from '../../Icons/Date'
    import LoaderItemsList from '../../PagesLoaders/ItemsList'
    import LoaderHeaderListWithoutBtn from '../../PagesLoaders/HeaderListWithoutBtn'
	import InfiniteLoading from 'vue-infinite-loading'
	import IconDownload from '../../Icons/Download'

    export default {
    	components: {
            IconArrow,
            IconUser,
            IconPeople,
            IconClose,
			IconSearch,
            LoaderItemsList,
            LoaderHeaderListWithoutBtn,
            IconDownload,
			InfiniteLoading,
			IconDate
        },
        data() {
            return {
            	loaded: true,
            	loadedList: false,
            	users: [],
            	sortBy: '',
            	sortDirection: 'asc',
				search_filter: '',
				queryFiltersCount: 0,
				page: 0,
            	infiniteId: 1,
            	maxDateCalendar: moment().format('YYYY-MM-DD'),
            	dateRange: {
            		startDate: moment().add(-30, 'days'),
            		endDate: moment()
            	},
            	calendarFilterSettings: {
            		firstDay: 1,
            		format: 'DD-MM-YYYY',
            		daysOfWeek:  moment.weekdaysMin(),
            		monthNames: moment.monthsShort()
            	},
            	usersStatusList: ['active', 'archived'],
            	selectedStatus: ''
            }
        },
        async mounted(){
			await this.checkQueryFilters()

        	this.$refs.infiniteLoading.status = 1
			this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
        },
       watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			},
			dateRange: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['startDate'] = moment(this.dateRange.startDate).format('DD-MM-YYYY');
				data['endDate'] =  moment(this.dateRange.endDate).format('DD-MM-YYYY');

				this.$router.push({query : data });
				this.searchFilterFunct()
			}
        },
        methods: {
			infiniteUsers($state){
				var paramsCall = { 
					view_more: this.page * 10, 
					start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'), 
					end_date: moment(this.dateRange.endDate).format('YYYY-MM-DD')
				}
				
				this.queryObject(paramsCall)

				if(this.sortBy){
        			paramsCall.sort_by = this.sortBy
        			paramsCall.sort_direction = this.sortDirection
        		}

				axios.get('/reports/' + this.$auth.user().instance.id + '/people', { params: paramsCall })
				.then(({data}) => {
					if(data.data.length) {
						this.page++
						this.users.push(...data.data)
						setTimeout(() => { $state.loaded() }, 200)
        				if(data.data.length < 10){
        					$state.complete()
        				}
					} else{
						$state.complete()
					}

        			this.loadedList = true
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }
					if(key == 'status'){ paramsCall.status = dataQuery[key].split(',') }
				});
			},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;
				this.searchFilterFunct()
			},
			editUser(userSlug){
				this.$router.push({ name: 'user-edit', params: {slug: userSlug} })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			donwloadXLS() {
				var showColumnsObj = ['name', 'email', 'responsibilities.total', 'responsibilities.accomplished', 'responsibilities.unaccomplished' ]

				var paramsCall = {}
				this.queryObject(paramsCall)
				paramsCall.type = 'xls'
				paramsCall.show_columns = showColumnsObj

				paramsCall.start_date = moment(this.dateRange.startDate).format('YYYY-MM-DD')
				paramsCall.end_date = moment(this.dateRange.endDate).format('YYYY-MM-DD')

				axios.get('/reports/' + this.$auth.user().instance.id + '/people', 
				{
					params: paramsCall,
                	responseType: 'blob'
				})
				.then((response) => {

					let blob = new Blob([response.data], { type:"application/octet-stream" })
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = this.$t('report_users.name_report_xls_users') + '.xlsx';

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
            },
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }
					if(key == 'startDate'){ this.dateRange.startDate = moment(dataQuery[key], 'DD-MM-YYYY') }
					if(key == 'endDate'){ this.dateRange.endDate = moment(dataQuery[key], 'DD-MM-YYYY') }
					if(key == 'status'){ this.selectedStatus = dataQuery[key].split(',') }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 2 : Object.keys(dataQuery).length - 1
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.users = []
				this.$refs.infiniteLoading.status = 1
				this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
			},
			selectSingleOptionFilter(filter, value){
        		if(this[filter] != value) {
	        		this[filter] = value
        		}else{
        			this[filter] = ''
        		}
        		
        		this.addFilterToRouteQuery(filter)
        	},
        	clearFilter(filter){
				if(filter == 'all'){
					this.selectedRoles = []
					this.selectedResponsibilities = []
					this.selectedWorkstations = []
					this.selectedDepartments = []
				}else{
					if(filter == 'selectedStatus') {
						this[filter] = ''
					} else {
						this[filter] = []
					}
				}

				this.addFilterToRouteQuery(filter)
			},
			addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedStatus' || filterName == 'all'){ dataQuery['status'] = this.selectedStatus.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length

				this.$router.push({query : dataQuery });
				this.searchFilterFunct()
			}
        }
    }
</script>

<style lang="scss" scoped>
	.row-space-tbf.search-row{
		&.reports {
			padding-top: 0;
		}
	} 
	.list-goals{
		.view-more{
            width: 100%;
            a{
                width: 100%;
                height: 38px;
                .text{
                    color: $grey;
                }
            }
        }
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}
		.column-avatar {
			&.column-name{
				.user-circle,
				img {
					margin-right: 20px;
					min-width: 36px;
				}
				.text{
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
			}
		}
		.column-name{
			flex: 1 1 auto;
			display: flex;
			align-items: center;
		}

		.column-total,
        .column-accomplished,
        .column-unaccomplished{
			flex: 0 0 200px;
			.link{
				cursor: pointer;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		@media (max-width: 1200px) {
			.column-total,
			.column-accomplished,
			.column-unaccomplished{
				flex: 0 0 150px;
			}
		}
		@media (max-width: 1070px) {
			.column-total,
			.column-accomplished,
			.column-unaccomplished{
				flex: 0 0 120px;
			}
		}
	}
	.list-empty .content {
		padding: 10px;
		padding-left: 0px;
		border-top: none;
		color: $grey
	}

	.vue-daterange-picker{
		&.calendar-filter{
			&.report-calendar{
				.form-control{
					height: 40px;
					.input-picker{
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						.icon{
							margin-right: 10px;
							justify-content: center;
							display: flex;
							svg{
								height: 16px;
								width: auto;
								.fill-color{
									fill: $placeholderColor;
								}
							}	
						}
						.text{
							color:$placeholderColor
						}
						.date{
							flex: 1;
							font-size: 12px;
							color: $primary;
							text-transform: capitalize;
							line-height: normal;
						}
					}
				}
			}
		}
	}

	
</style>